import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Card,
  Spin,
  Tag,
  Button,
  Select,
  message,
  Row,
  Form,
  DatePicker,
  Typography,
  notification,
  Col,
  Tooltip,
  Menu,
  Dropdown,
} from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import ProposalService from "../../services/ProposalService";
import StatusService from "../../modules/Status/Service/StatusService";
import UserService from "../../services/UserService";
import { formatDate, formatToTL, formatToUSD, formatToEUR, formatDateString } from "../../utils";
import tinycolor from "tinycolor2";
import dayjs from "dayjs";
import * as XLSX from "xlsx"; // Excel
import { saveAs } from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";
import PaymentModal from "../ProjectFollowUp/PaymentModal";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;

interface Proposal {
  proposalNumber: string;
  id: any;
  paidAmount: any;
  createdAt: string;
  invoiceDate?: string;
  paidDate?: string;
  isPaid?: boolean;
  account: {
    firstName: string;
    lastName: string;
  };
  company: {
    name: string;
  };
  statusId: number;
  totalPrice: number;
  incomeSharePrice?: number;
}

interface Status {
  id: number;
  name: string;
  color: string;
}

interface CustomerItem {
  id: number;
  firstName: string;
  lastName: string;
}

/**
 * incomeShareSummary -> { totalIncomeSharePrice: number, count: number }
 */
const ProposalPaymentPage: React.FC = () => {
  const [form] = Form.useForm();
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  // Döviz kurları
  const [usdRate, setUsdRate] = useState<number>(1);
  const [eurRate, setEurRate] = useState<number>(1);

  // Filtre
  const [company, setCompany] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [dateFilter, setDateFilter] = useState<{ startDate: string | null; endDate: string | null }>({
    startDate: null,
    endDate: null,
  });
  const [selectedStatusId, setSelectedStatusId] = useState<number | null>(4);
  const [selectedPaidStatus, setPaidSelectedStatus] = useState<any>();
  const [sortField, setSortField] = useState<string>("createdAt");
  const [sortDirection, setSortDirection] = useState<string>("ASC");

  // Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  // Kullanıcı listesi
  const [userList, setUserList] = useState<CustomerItem[]>([]);
  // Ödeme Modal
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentModalType, setPaymentModalType] = useState<any>();
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);

  // incomeShareSummary -> { totalIncomeSharePrice, count }
  const [incomeShareSummary, setIncomeShareSummary] = useState<any>(null);

  useEffect(() => {
    if (!isPaymentModalVisible) {
      fetchData();
    }
  }, [isPaymentModalVisible]);

  useEffect(() => {
    // Döviz kurları
    fetch("https://api.frankfurter.app/latest?from=TRY&to=USD,EUR")
      .then((res) => res.json())
      .then((data) => {
        if (data && data.rates) {
          setUsdRate(data.rates.USD);
          setEurRate(data.rates.EUR);
        }
      })
      .catch((err) => {
        console.error("Döviz kurları çekilirken hata oluştu:", err);
      });
  }, []);

  // Statüleri çek
  const fetchStatuses = async () => {
    try {
      const response: any = await StatusService.getAll();
      const allowedIds = [2, 3, 4, 5, 6, 9, 10, 11];
      const filteredStatuses = (response.data || []).filter((status: any) => allowedIds.includes(status.id));
      setStatuses(filteredStatuses);
    } catch (error) {
      console.error("Statüler alınırken hata oluştu:", error);
      notification.error({
        message: "Hata",
        description: "Statüler alınırken hata oluştu.",
      });
    }
  };

  // Kullanıcı listesini çek
  const fetchUserList = useCallback(async (searchTerm?: string) => {
    try {
      const response: any = await UserService.getAll({
        name: searchTerm || "",
        page: 0,
        size: 20,
        sortField: "createdAt",
        sortDirection: "DESC",
      });
      setUserList(response.data?.content || []);
    } catch (err) {
      console.error("Kullanıcı verileri çekilirken hata oluştu:", err);
      message.error("Kullanıcı listesi alınamadı");
    }
  }, []);

  /**
   * Paginated verileri ve incomeShareSummary özetini çek
   */
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const commonParams: any = {
        company,
        sortField,
        sortDirection,
        ...(dateFilter.startDate && { startInvoiceDate: dateFilter.startDate }),
        ...(dateFilter.endDate && { endInvoiceDate: dateFilter.endDate }),
        ...(selectedUserId && { accountId: selectedUserId }),
      };

      // PAGINATED veriler
      const getAllParams = {
        ...commonParams,
        page: currentPage - 1,
        size: pageSize,
        ...(selectedStatusId && { statuIds: selectedStatusId.toString() }),
        isPaid: selectedPaidStatus !== null ? selectedPaidStatus : null,
      };
      const response: any = await ProposalService.getAll(getAllParams);
      let proposalsData: Proposal[] = [];
      if (Array.isArray(response.data)) {
        proposalsData = response.data;
      } else if (response.data.content && Array.isArray(response.data.content)) {
        proposalsData = response.data.content;
      }
      setProposals(proposalsData);
      setTotalItems(response.data.totalElements);

      // incomeShareSummary verisi
      const summaryParams = {
        startDate: dateFilter.startDate || undefined,
        endDate: dateFilter.endDate || undefined,
        statusId: selectedStatusId || undefined,
        accountId: selectedUserId || undefined,
        isPaid: selectedPaidStatus !== null ? selectedPaidStatus : undefined,
      };
      const incomeShareResp = await ProposalService.getIncomeShareSummary(summaryParams);
      setIncomeShareSummary(incomeShareResp.data); // { totalIncomeSharePrice, count }
    } catch (error) {
      console.error("Teklif verileri alınırken hata oluştu:", error);
      notification.error({
        message: "Hata",
        description: "Teklif verileri alınırken hata oluştu.",
      });
    } finally {
      setLoading(false);
    }
  }, [
    company,
    selectedUserId,
    dateFilter,
    selectedStatusId,
    currentPage,
    pageSize,
    sortField,
    sortDirection,
    selectedPaidStatus,
  ]);

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onValuesChange = (changedValues: any, allValues: any) => {
    if ("dateRange" in changedValues) {
      if (allValues.dateRange && allValues.dateRange.length === 2) {
        const startDate = allValues.dateRange[0].format("YYYY-MM-DD");
        const endDate = allValues.dateRange[1].format("YYYY-MM-DD");
        setDateFilter({ startDate, endDate });
      } else {
        setDateFilter({ startDate: null, endDate: null });
      }
    }
    if ("company" in changedValues) {
      setCompany(allValues.company || "");
    }
    if ("user" in changedValues) {
      setSelectedUserId(allValues.user || null);
    }
    setCurrentPage(1);
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<Proposal> | SorterResult<Proposal>[]
  ) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
    const singleSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    if (singleSorter && singleSorter.field && singleSorter.order && singleSorter.field !== "statusId") {
      setSortField(singleSorter.field as string);
      setSortDirection(singleSorter.order === "ascend" ? "ASC" : "DESC");
    } else {
      setSortField("createdAt");
      setSortDirection("ASC");
    }
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((st) => st.id === statusId);
    return status ? status.name : "Bilinmiyor";
  };

  // Excel: Ödeme Raporu
  const handleExportPaymentReport = async () => {
    try {
      setLoading(true);
      const params = {
        startInvoiceDate: dateFilter.startDate || undefined,
        endInvoiceDate: dateFilter.endDate || undefined,
      };
      const response: any = await ProposalService.getPaymentReportData(params);
      const data: any[] = response.data || [];

      const formatPaymentDate = (dateStr: string) => {
        return dateStr ? dayjs(dateStr).format("DD/MM/YYYY") : "-";
      };

      const header = [
        "Ödenme Tarihi",
        "Teklif Numarası",
        "İş Ortağı Adı",
        "İş Ortağı Soyadı",
        "TC Kimlik No",
        "Adres",
        "Brüt Tutar",
        "Stopaj Tutarı",
        "Net Ödenecek Tutar",
      ];

      const dataRows = data.map((item) => [
        formatPaymentDate(item.paymentDate),
        item.proposalNumber,
        item.partnerFirstName,
        item.partnerLastName,
        item.tcKimlikNo,
        item.address || "-",
        item.grossAmount,
        item.stopajAmount,
        item.netAmount,
      ]);

      const sheetData = [header, ...dataRows];
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Ödeme Raporu");

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "odeme_raporu.xlsx");
    } catch (err) {
      console.error("Payment report export error:", err);
      message.error("Ödeme raporu indirme hatası");
    } finally {
      setLoading(false);
    }
  };

  // Excel: Teklif Raporu (filtreye göre tüm veriyi çekerek)
  const handleExportExcel = async () => {
    try {
      setLoading(true);
      // Filtre parametreleri; getAllWithoutPagination servisi tüm veriyi çekecek
      const exportParams = {
        company,
        sortField,
        sortDirection,
        ...(dateFilter.startDate && { startInvoiceDate: dateFilter.startDate }),
        ...(dateFilter.endDate && { endInvoiceDate: dateFilter.endDate }),
        ...(selectedUserId && { accountId: selectedUserId }),
        ...(selectedStatusId && { statuIds: selectedStatusId.toString() }),
        isPaid: selectedPaidStatus !== null ? selectedPaidStatus : undefined,
      };
      const response: any = await ProposalService.getAllWithoutPagination(exportParams);
      const data: Proposal[] = Array.isArray(response.data) ? response.data : [];

      const exportData = data.map((proposal) => {
        // Net tutar üzerinden brüt ve stopaj hesaplaması: 
        // Brüt = net × 1.2, Stopaj = net × 0.2
        const netAmount = proposal.totalPrice;
        const grossAmount = netAmount * 1.2;
        const stopajAmount = netAmount * 0.2;

        let odemeSuresi = "-";
        if (proposal.invoiceDate) {
          const adjustedDueDate = dayjs(proposal.invoiceDate).add(30, "days");
          if (proposal.paidDate) {
            const diff = adjustedDueDate.diff(dayjs(proposal.paidDate), "days");
            if (diff > 0) {
              odemeSuresi = `${diff} gün kala ödendi`;
            } else if (diff === 0) {
              odemeSuresi = "Tam zamanında ödendi";
            } else {
              odemeSuresi = "Gecikmeli ödendi";
            }
          } else {
            const today = dayjs();
            const diff = adjustedDueDate.diff(today, "days");
            odemeSuresi = diff <= 0 ? "Süresi Geçmiş" : `${diff} gün`;
          }
        }

        return {
          "Teklif Numarası": proposal.proposalNumber,
          "Teklif Tarihi": formatDate(proposal.createdAt),
          "İş Ortağı": `${proposal.account.firstName} ${proposal.account.lastName}`,
          "Firma Adı": proposal.company.name,
          "Statü": proposal.isPaid ? "Ödendi" : "Bekleniyor",
          "Ödeme Tarihi": proposal.isPaid
            ? proposal.paidDate
              ? formatDateString(proposal.paidDate)
              : "-"
            : "-",
          "Ödeme Süresi": odemeSuresi,
          "Teklif Tutarı (TL)": formatToTL(netAmount),
          "Brüt Tutar (TL)": formatToTL(grossAmount),
          "Stopaj Tutarı (TL)": formatToTL(stopajAmount),
          "Teklif Tutarı (USD)": usdRate ? formatToUSD(netAmount * usdRate) : "-",
          "Teklif Tutarı (EUR)": eurRate ? formatToEUR(netAmount * eurRate) : "-",
          "Ödeme Tutarı": proposal.incomeSharePrice ? formatToTL(proposal.incomeSharePrice) : "-",
        };
      });

      // Toplamlar için son satır
      const totalNet = data.reduce((sum, p) => sum + p.totalPrice, 0);
      const totalGross = totalNet * 1.2;
      const totalStopaj = totalNet * 0.2;
      const totalIncome = data.reduce((sum, p) => sum + (p.incomeSharePrice ? p.incomeSharePrice : 0), 0);
      const totalUSD = totalNet * usdRate;
      const totalEUR = totalNet * eurRate;

      const totalRow = {
        "Teklif Numarası": "Toplam",
        "Teklif Tarihi": "",
        "İş Ortağı": "",
        "Firma Adı": "",
        "Statü": "",
        "Ödeme Tarihi": "",
        "Ödeme Süresi": "",
        "Teklif Tutarı (TL)": formatToTL(totalNet),
        "Brüt Tutar (TL)": formatToTL(totalGross),
        "Stopaj Tutarı (TL)": formatToTL(totalStopaj),
        "Teklif Tutarı (USD)": usdRate ? formatToUSD(totalUSD) : "-",
        "Teklif Tutarı (EUR)": eurRate ? formatToEUR(totalEUR) : "-",
        "Ödeme Tutarı": totalIncome ? formatToTL(totalIncome) : "-",
      };

      exportData.push(totalRow);

      const worksheet = XLSX.utils.json_to_sheet(exportData, { skipHeader: false });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Proposals");

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "proposals.xlsx");
    } catch (err) {
      console.error("Excel export error:", err);
      message.error("Excel indirirken hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  // Tablonun kolonları
  const columns: ColumnsType<Proposal> = [
    {
      title: "Teklif Numarası",
      dataIndex: "proposalNumber",
      key: "proposalNumber",
      sorter: true,
    },
    {
      title: "İş Ortağı",
      key: "account",
      sorter: true,
      render: (_: any, record: Proposal) =>
        `${record.account.firstName} ${record.account.lastName}`,
    },
    {
      title: "Ödeme Tarihi",
      dataIndex: "paidDate",
      key: "paidDate",
      sorter: true,
      render: (_: any, record: Proposal) =>
        record.isPaid ? (record.paidDate ? formatDateString(record.paidDate) : "-") : "-",
    },
    {
      title: "Durum",
      dataIndex: "statusId",
      key: "status",
      render: (statusId: number, record: any) => (
        <Tag
          style={{
            color: record.isPaid ? "#55DA69" : "#FF4D4F",
            backgroundColor: record.isPaid ? "#E6F7E6" : "#FFF2F0",
            borderRadius: "12px",
            padding: "5px 10px",
            fontSize: "14px",
            display: "inline-block",
            minWidth: "100px",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          {record.isPaid ? "Ödendi" : "Bekleniyor"}
        </Tag>
      ),
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      sorter: true,
      render: (date: string) => formatDateString(date),
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      sorter: true,
      render: (price: number) => (price ? formatToTL(price) : "-"),
    },
    {
      title: "Ödeme Süresi",
      key: "paymentDuration",
      render: (_: any, record: Proposal) => {
        const { invoiceDate, paidDate } = record;
        if (!invoiceDate) {
          return <span>-</span>;
        }
        const adjustedDueDate = dayjs(invoiceDate).add(30, "days");
        let diff = 0;
        let tooltipText: string | null = null;
        let colorHex = "#2f54eb";

        if (paidDate) {
          diff = adjustedDueDate.diff(dayjs(paidDate), "days");
          if (diff > 0) {
            tooltipText = `${diff} gün kala ödendi`;
          } else if (diff === 0) {
            tooltipText = "Tam zamanında ödendi";
          } else {
            tooltipText = "Gecikmeli ödendi";
          }
        } else {
          const today = dayjs();
          diff = adjustedDueDate.diff(today, "days");
          if (diff <= 0) {
            colorHex = "#ff4d4f";
            diff = 0;
            tooltipText = "Süresi Geçmiş";
          } else if (diff <= 10) {
            colorHex = "#fa8c16";
          } else {
            colorHex = "#52c41a";
          }
        }

        const color = tinycolor(colorHex);
        const background = color.setAlpha(0.1).toRgbString();
        const borderColor = color.setAlpha(0.5).toRgbString();
        const tagEl = (
          <Tag
            style={{
              color: colorHex,
              backgroundColor: background,
              borderColor: borderColor,
              borderRadius: "12px",
              padding: "5px 10px",
              fontSize: "14px",
              display: "inline-block",
              minWidth: "50px",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {diff}
          </Tag>
        );
        return tooltipText ? <Tooltip title={tooltipText}>{tagEl}</Tooltip> : tagEl;
      },
    },
    {
      title: "Teklif Tutarı (TL)",
      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: true,
      render: (price: number) => formatToTL(price),
    },
    {
      title: "Teklif Tutarı (USD)",
      key: "usdPrice",
      sorter: true,
      render: (_: any, record: Proposal) => {
        if (usdRate) {
          const usdValue = record.totalPrice * usdRate;
          return formatToUSD(usdValue);
        }
        return "-";
      },
    },
    {
      title: "Teklif Tutarı (EUR)",
      key: "eurPrice",
      sorter: true,
      render: (_: any, record: Proposal) => {
        if (eurRate) {
          const eurValue = record.totalPrice * eurRate;
          return formatToEUR(eurValue);
        }
        return "-";
      },
    },
    {
      title: "Ödeme Tutarı",
      dataIndex: "incomeSharePrice",
      key: "incomeSharePrice",
      sorter: true,
      render: (price: number) => (price ? formatToTL(price) : "-"),
    },
    {
      title: "İşlemler",
      key: "actions",
      render: (_: any, record: Proposal) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="payment"
                onClick={() => openPaymentModal(record.id, "edit")}
                disabled={record.paidAmount}
              >
                Ödeme Yap
              </Menu.Item>
              <Menu.Item
                key="upload"
                disabled={record.paidAmount == null}
                onClick={() => openPaymentModal(record.id, "view")}
              >
                Ödeme Görüntüle
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="default" className="d-button action-button">
            İşlemler
          </Button>
        </Dropdown>
      ),
    },
  ];

  const openPaymentModal = (proposalId: number, type: any) => {
    setSelectedProposalId(proposalId);
    setIsPaymentModalVisible(true);
    setPaymentModalType(type);
  };

  const handlePaidStatusChange = (value: any) => {
    setPaidSelectedStatus(value);
    setCurrentPage(1);
  };

  return (
    <Card style={{ marginBottom: "120px" }}>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="inline"
          onValuesChange={onValuesChange}
          style={{ marginBottom: 16, width: "100%" }}
        >
          <Row align="middle" justify="end" style={{ width: "100%" }}>
            <Form.Item name="dateRange">
              <RangePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name="status">
              <Select
                allowClear
                placeholder="Statü seçin"
                style={{ width: 200, marginRight: 8, paddingLeft: 10 }}
                onChange={handlePaidStatusChange}
              >
                <Option key={0} value={true}>
                  Ödendi
                </Option>
                <Option key={1} value={false}>
                  Bekleniyor
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name="user">
              <Select<number>
                showSearch
                allowClear
                placeholder="İş Ortağı Seçiniz"
                style={{ width: 250 }}
                onSearch={fetchUserList}
                filterOption={false}
              >
                {userList.map((user: CustomerItem) => (
                  <Option key={user.id} value={user.id}>
                    {user.firstName} {user.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* Ödeme Raporu Excel */}
            <Form.Item>
              <Button
                type="primary"
                onClick={handleExportPaymentReport}
                icon={<DownloadOutlined />}
              >
                Ödeme Raporu
              </Button>
            </Form.Item>
            {/* Teklif Raporu Excel */}
            <Form.Item>
              <Button
                type="default"
                onClick={handleExportExcel}
                icon={<DownloadOutlined />}
                className="width-auto d-button"
              >
                Teklif Raporu
              </Button>
            </Form.Item>
          </Row>
        </Form>

        {/* Dört kutuluk özet: incomeShareSummary */}
        {incomeShareSummary && (
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Text strong style={{ fontSize: 16, color: "#777C88" }}>
                  Teklif Sayısı
                </Text>
                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 8 }}>
                  {incomeShareSummary.count || 0}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Text strong style={{ fontSize: 16, color: "#777C88" }}>
                  Toplam Ödeme Tutarı (TL)
                </Text>
                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 8 }}>
                  {formatToTL(incomeShareSummary.totalIncomeSharePrice || 0)}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Text strong style={{ fontSize: 16, color: "#777C88" }}>
                  Toplam Ödeme Tutarı (USD)
                </Text>
                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 8 }}>
                  {usdRate
                    ? formatToUSD((incomeShareSummary.totalIncomeSharePrice || 0) * usdRate)
                    : "-"}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card bordered={false} style={{ backgroundColor: "#FAFAFA", borderRadius: 8 }}>
                <Text strong style={{ fontSize: 16, color: "#777C88" }}>
                  Toplam Ödeme Tutarı (EUR)
                </Text>
                <div style={{ fontSize: 24, fontWeight: "bold", marginTop: 8 }}>
                  {eurRate
                    ? formatToEUR((incomeShareSummary.totalIncomeSharePrice || 0) * eurRate)
                    : "-"}
                </div>
              </Card>
            </Col>
          </Row>
        )}

        <Table<Proposal>
          columns={columns}
          dataSource={proposals}
          rowKey="proposalNumber"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
            total: totalItems,
          }}
          onChange={handleTableChange}
        />
      </Spin>

      {isPaymentModalVisible && selectedProposalId && (
        <PaymentModal
          visible={isPaymentModalVisible}
          onClose={() => setIsPaymentModalVisible(false)}
          proposalId={selectedProposalId}
          mode={paymentModalType}
        />
      )}
    </Card>
  );
};

export default ProposalPaymentPage;